define("discourse/plugins/discourse-gamification/discourse/templates/admin-plugins/show/discourse-gamification-leaderboards/show", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <DBreadcrumbsItem as |linkClass|>
    <LinkTo
      @route="adminPlugins.show.discourse-gamification-leaderboards.index"
      class={{linkClass}}
    >
      {{i18n "gamification.leaderboard.title"}}
    </LinkTo>
  </DBreadcrumbsItem>
  <AdminEditLeaderboard @leaderboard={{@model}} />
  */
  {
    "id": "3TscnHH0",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],[[16,0,[30,1]]],[[\"@route\"],[\"adminPlugins.show.discourse-gamification-leaderboards.index\"]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,2],[\"gamification.leaderboard.title\"],null]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"],[8,[39,3],null,[[\"@leaderboard\"],[[30,2]]],null]],[\"linkClass\",\"@model\"],false,[\"d-breadcrumbs-item\",\"link-to\",\"i18n\",\"admin-edit-leaderboard\"]]",
    "moduleName": "discourse/plugins/discourse-gamification/discourse/templates/admin-plugins/show/discourse-gamification-leaderboards/show.hbs",
    "isStrictMode": false
  });
});